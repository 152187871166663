#top-menu a,
#top-menu button {
  padding: 0 15px;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  /* color: #fff; */
}

.nav-link {
  color: black;
  text-decoration: none;
  font-size: 20px !important;
}

.login-btn {
  padding: 0 15px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-decoration: none;
}

.nav-menu a {
  color: black !important;
}

.Logo {
  text-decoration: none !important;
  color: black !important;
}

#topmenu button {
  display: none !important;
}

#top-menu {
  display: block;
}

.folsol-logo {
  width: 150px;
  height: 50px;
}

.navbar {
  background: red !important;
  transition: 0.3s ease-in-out;
}

.navbar-active {
  background: white;
  box-shadow: none !important ;
}

@media screen and (max-width: 600px) {
  #top-menu {
    display: none;
  }

  .mobile-menu a,
  .mobile-menu button {
    color: #000 !important;
  }

  .icon {
    float: right;
    /* display: block !important; */
  }
  #topmenu button {
    display: block !important;
  }
  #about-menu {
    padding-left: 15px;
    text-transform: unset;
    font-size: inherit;
  }
}

@media screen and (max-width: 1051px) {
  #top-menu {
    display: none;
  }

  .mobile-menu a {
    color: #000 !important;
  }

  .icon {
    float: right;
    /* display: block !important; */
  }
  #topmenu button {
    display: block !important;
  }
  #about-menu {
    padding-left: 15px;
    text-transform: unset;
    font-size: inherit;
  }
}

.logout-btn {
  outline: none;
  border: none;
  padding: 15px;
  background: white;
  color: coral;
  font-weight: bold;
  border-radius: 25px;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
}

.business-change {
  background: #fa7901 !important;
  color: #fff !important;
}

.business-dropdown {
  top: 16px !important;
  left: 1207px !important;
}

.business-dropdown div {
  top: 20px !important;
  left: 16px !important;
}

.dropdown-arrow {
}
