.footer-copyright .container {
  background: #000;
  color: #fff;
  text-align: center;
  border-top: 1px solid #fff;
}

.page-footer div div {
  display: flex;
  justify-content: center;
}

.copyright-text {
  text-align: center;
}

.page-footer {
  background: #eaf3fa;
  color: black;
}

.facebook {
  color: #3b5998;
}

.twitter {
  color: #55acee;
}

.linkedin {
  color: #007bb5;
}

.pinterest {
  color: #cb2027;
}
.instagram {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
  color: white;
  padding: 1px;
  border-radius: 5px;
}
.youtube {
  color: #ff0000;
}

.language-button {
  display: unset !important;
}

@media screen and (max-width: 600px) {
  .page-footer div div {
    display: inline-table;
    text-align: center;
    width: 112px;
  }
}
