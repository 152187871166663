.side-panel {
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  height: 100%;
  z-index: 20;
}

.side-panel.visible {
  width: 40%;
}

.toggle-button {
  position: fixed;
  color: #555555;
  background: white;
  border: 2px;
  top: 20px;
  left: 195px;
  z-index: 20;
}

.toggle-button-close {
  position: fixed;
  color: #555555;
  background: white;
  border: 2px;
  top: 20px;
  left: 505px;
  z-index: 20;
}

.comments-side-panel {
  width: 0;
  position: fixed;
  top: 10%;
  right: 0;
  bottom: 5%;
  overflow-x: hidden;
  transition: 0.5s;
  height: 90%;
  z-index: 0;
}

.comments-side-panel.visible {
  width: 16%;
  right: 10px;
}

.comments-toggle-button {
  position: fixed;
  top: 150px;
  right: 0;
  transition: right 0.5s ease;
}

.comments-toggle-button-close {
  position: fixed;
  color: #555555;
  background: white;
  border: 2px;
  top: 150px;
  transform: translateX(-50%);
  transition: right 0.5s ease;
}

.comments-side-panel.visible + .comments-toggle-button {
  right: 20%;
}

.scroll-width::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 10px;
}

.scroll-width::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 2px;
  margin-left: 10px;
}

.search-input {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: 185px;
  opacity: 0;
  transform: translateX(-100%);
}

.search-input-active {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-out {
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.title-container {
  transform: translateX(0px);
}

.search-container {
  transform: translateX(-100%);
  opacity: 0;
}

.search-container-active {
  transform: translateX(0px);
  opacity: 1;
}

.hide-left {
  transform: translateX(-100%);
}

.transition-slide {
  transition: transform 1.3s ease;
}

.slide-version-left {
  transform: translateX(-300px);
}

.slide-version-center {
  transform: translateX(0%);
}

.slide-input-right {
  transform: translateX(30px);
}

.slide-input-center {
  transform: translateX(-200px);
}

.comment-transition-slide {
  transition: transform 1.3s ease;
}

.comment-slide-version-left {
  transform: translateX(-300px);
}

.comment-slide-version-center {
  transform: translateX(0%);
}

.comment-slide-input-right {
  transform: translateX(20px);
}

.comment-slide-input-center {
  transform: translateX(-200px);
}

.version-row {
  width: 193px;
}

@media screen and (max-width: 1900px) {
  .version-row {
    width: 123px;
  }
}

@media screen and (max-width: 1503px) {
  .version-row {
    width: 220px;
  }
}

@media screen and (max-width: 2123px) {
  .version-row {
    width: 250px;
  }

  .slide-input-right {
    transform: translateX(100px);
  }

  .transition-slide input {
    width: 212px;
  }
}

@media screen and (max-width: 1737px) {
  .version-row {
    width: 220px;
  }

  .transition-slide input {
    width: 193px;
  }
}

@media screen and (max-width: 1463px) {
  .version-row {
    width: 193px;
  }

  .transition-slide input {
    width: 164px;
  }
}

@media screen and (max-width: 1300px) {
  .version-row {
    width: 180px;
  }

  .transition-slide input {
    width: 154px;
  }

  .search-icon {
    margin-right: 5px;
  }

  .header-size {
    font-size: 15px;
    width: 150px !important;
  }

  .slide-input-center {
    transform: translateX(-180px);
  }
}

@media screen and (max-width: 1200px) {
  .version-row {
    width: 160px;
  }
}

/*for comments*/

@media screen and (max-width: 2123px) {
  .comment-container {
    height: 495px;
  }

  .comment-search {
    width: 212px !important;
  }

  .comment-slide-input-right {
    transform: translateX(50px);
  }

  .comment-section {
    height: 400px;
  }

  .slide-input-right {
    transform: translateX(100px);
  }

  .comment-card {
    width: 250px;
    margin-left: 0.7rem;
  }

  .comment-input-box {
    width: 255px !important;
  }

  .comment-input-box input {
    width: 205px !important;
  }

  .access-to-digital-locker-left {
    position: absolute;
    left: -250px;
  }

  .access-to-digital-locker-right {
    position: absolute;
    right: 1268px;
  }
}

@media screen and (max-width: 1737px) {
  .comment-container {
    height: 415px;
  }

  .comment-section {
    height: 320px;
  }

  .comment-input-container {
    width: 220px;
  }

  .comment-card {
    width: 220px;
    margin-left: 0.7rem;
  }

  .comment-input-box {
    width: 220px !important;
  }

  .comment-input-box input {
    width: 180px !important;
  }

  .access-to-digital-locker-left {
    position: absolute;
    left: -255px;
  }

  .access-to-digital-locker-right {
    position: absolute;
    right: 1110px;
  }
}

@media screen and (max-width: 1463px) {
  .comment-container {
    height: 350px !important;
  }

  .comment-input-container {
    width: 150px !important;
  }

  .comment-input-container input {
    width: 165px !important;
    margin-right: 5px !important;
  }

  .comment-section {
    height: 250px !important;
  }

  .comment-card {
    width: 190px;
    margin-left: 0.7rem;
  }

  .comment-input-box {
    width: 200px !important;
  }

  .comment-input-box input {
    width: 153px !important;
  }

  .access-to-digital-locker-left {
    position: absolute;
    left: -254px;
  }

  .access-to-digital-locker-right {
    position: absolute;
    right: 980px;
  }
}

@media screen and (max-width: 1300px) {
  .comment-container {
    height: 300px !important;
  }

  .comment-search {
    margin-left: 18px !important;
  }

  .comment-input-container input {
    width: 155px !important;
    margin-right: 5px !important;
  }

  .comment-section {
    height: 200px !important;
  }

  .comment-card {
    width: 180px;
    margin-left: 0.7rem;
  }

  .comment-input-box {
    width: 180px !important;
  }

  .comment-input-box input {
    width: 138px !important;
    margin-left: 13px;
  }

  .comment-header {
    margin-right: 22px !important;
  }

  .transition-slide input {
    width: 154px;
  }

  .search-icon {
    margin-right: 5px;
  }

  .header-size {
    font-size: 15px;
    width: 150px !important;
  }

  .slide-input-center {
    transform: translateX(-180px);
  }
}

@media screen and (max-width: 1200px) {
  .comment-container {
    width: 160px;
  }

  .comment-card {
    width: 120px !important;
    margin-left: 0.7rem;
  }
}
