header {
  background-color: #000 !important;
}

.MuiFormControl-root {
  display: flex !important;
}

.MuiIconButton-colorPrimary {
  color: #ff0000 !important;
}

input[type="submit"],
.MuiButton-containedPrimary {
  outline: none !important;
  padding: 8px 20px !important;
  /* border-radius: 25px !important; */
  transition: 0.1s ease-in !important;
  background: #fdd835 !important;
  color: #000 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.MuiButton-contained {
  outline: none !important;
  padding: 11px 20px !important;
  /* border-radius: 25px !important; */
  transition: 0.1s ease-in !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.MuiSnackbar-anchorOriginBottomCenter {
  left: 85% !important;
  bottom: 24px !important;
  transform: none !important;
  right: 20px !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #fa7901 !important;
}

/* .MuiFormControl-root {
  display: flex !important;
} */

.MuiChip-colorPrimary {
  background-color: #2ecc71 !important;
}

.MuiChip-colorSecondary {
  background-color: #e74c3c !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgb(205 197 197) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #fa7901 !important;
}

.PrivateRadioButtonIcon-layer-13 {
  left: 7px !important;
}

.error-message {
  color: red;
  max-width: 300px;
  overflow-wrap: break-word;
}

.chip {
  height: 20px !important;
  right: -70px !important;
}

.sidebar-menu a {
  text-decoration: none;
  color: #000;
}

.list-item {
  text-decoration: none !important;
  font-weight: 400;
  font-variant-caps: all-petite-caps;
}

.active,
.active span {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: 600;
  font-weight: bold !important;
  font-variant-caps: all-petite-caps;
}

.active .list-item {
  background: #fdd835 !important;
  color: #000;
}

.MuiGrid-spacing-xs-3 {
  width: 100% !important;
  margin: 0 !important;
}

.public-layout {
  background: #ffffff;
}

.Toastify__toast {
  min-height: 50px !important;
}

.goog-te-banner-frame {
  left: unset !important;
  top: unset !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 39% !important;
}

.label-help a {
  @apply text-blue-500;
}

.first-capitalize::first-letter {
  text-transform: uppercase;
}
