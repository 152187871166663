@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Bahnscrift;
  src: url("assets/fonts/fonts/BAHNSCHRIFT.woff");
}
/* @font-face {
  font-family: Inter;
  src: url('https://fonts.cdnfonts.com/css/inter');
} */

html {
  scroll-behavior: smooth;
  color-scheme: light !important;
}

* {
  box-sizing: border-box;
}

*,
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color-scheme: light !important;
}

body::-webkit-scrollbar {
  background: whitesmoke;
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: whitesmoke;
}

body::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 20px;
  background: rgb(207, 207, 207);
}

body::webkit-scrollbar- * {
  box-sizing: border-box;
}

p {
  font-size: 1rem;
  /* font-weight: 600; */
  line-height: 30px;
}

.text-left {
  text-align: left !important;
  padding-left: 7px;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.p-2 {
  padding: 10px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pt-2 {
  padding-top: 10px;
}

.banner-image {
  height: 330px;
  width: 1900px;
  object-fit: cover;
}

.seperator {
  border-bottom: 1px solid #a2a2a2;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.container {
  padding-top: 100px;
  padding-bottom: 100px;
}

.container div h2 {
  padding-bottom: 50px !important;
}

a {
  text-decoration: none;
}

.Toastify__toast-container--top-right {
  top: 5em !important;
  right: 1em;
}

.main {
  margin-top: 73px;
}

.field-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
  margin-bottom: 5px;
}

.error-message {
  color: red;
  background: rgb(255, 234, 234);
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  display: flex;
}

.error-message .warning-icon {
  margin-right: 10px;
}

.blue-input {
  @apply w-full rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm my-2;
}

.bg-primary {
  @apply bg-orange-500 text-white hover:bg-orange-700;
}

.text-primary {
  @apply text-orange-500 hover:text-orange-700;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.disabled {
  pointer-events: none;
  opacity: 0.9;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
